import React from 'react';
import { graphql } from 'gatsby';

import { ThemeProvider } from '@/themes/theme';
import { GlobalStyle } from '@/themes/globalStyle';
import Wrapper from '@/components/Wrapper';
import { Header } from '@/components/Header';
import { TemplateSlug } from '@/components/Templates';
import { Footer } from '@/components/Footer';
import { removeSpaces } from '@/utils/functions';
import { Meta } from '@/components/general/Meta';
import { Links } from '@/utils/constants';

const TemplateSlugPage = ({ pageContext, data }) => (
  <ThemeProvider>
    <Meta
      title={`${pageContext.templateTitle} | CodePlatform`}
      description={data.contentfulTemplate.description.description}
      image={data.contentfulTemplate.mainIcon.url}
      url={`${Links.TEMPLATES}/${removeSpaces(pageContext.templateTitle)}`}
    />
    <GlobalStyle />
    <Wrapper $overflowNotHidden>
      <Header />
      <TemplateSlug data={data.contentfulTemplate} />
      <Footer />
    </Wrapper>
  </ThemeProvider>
);

export default TemplateSlugPage;

export const query = graphql`
  query TemplateQuery($templateId: String) {
    contentfulTemplate(id: { eq: $templateId }) {
      title
      mainIcon {
        url
      }
      additionalIcon {
        url
      }
      categories
      technologies
      languages
      description {
        description
      }
      studioImage {
        url
      }
      bootstrap
      mainScreens {
        collectionName
        collection {
          description
          gatsbyImageData(width: 521, height: 1128, placeholder: BLURRED)
        }
      }
      richText {
        raw
      }
      features {
        feature
        description {
          description
        }
      }
      studioTemplateId
      sliderImages {
        title
        gatsbyImageData
        url
      }
      includesList {
        icon {
          url
        }
        title
        description
      }
    }
  }
`;
